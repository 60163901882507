@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Inter", sans-serif;
}

/* #map {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #search-control {
    margin: "10px 0 0 10px";
  }
  .what3words-autosuggest-input-wrapper > input:nth-child(1) {
    width: 300px;
  } */

/* #map {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 10px;
  padding: 0;
} */
