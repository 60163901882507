:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #16a34a;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #dc2626;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

.map-container {
  height: 100%;
  width: 100%;
}

.test {
  height: 100px;
  width: 100px;
}

p {
  margin-bottom: 1rem;
}

h1 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1.25rem;
}

h2 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

h3 {
  font-weight: 600;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

a {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
}
